// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-dropdown .dropdown-menu {
  min-width: 85px;
}
.plan-dropdown .btn {
  border: 0;
  background: none;
  padding: 0;
  color: #253944;
  font-size: 16px;
}
.plan-dropdown .btn:hover {
  border: 0;
  color: #253944;
}
.plan-dropdown .btn:focus {
  background: none;
}
.plan-dropdown .btn.active {
  background-color: transparent !important;
  color: #253944 !important;
}
.plan-dropdown .btn:active {
  color: #253944 !important;
  background-color: transparent !important;
}
.plan-dropdown .btn svg {
  color: #253944;
  width: 10px;
  height: 10px;
}

.plan-list.active {
  color: #ffffff !important;
  background-color: #4ca971 !important;
}

.dropdown-plan-list {
  list-style: none;
  padding: 8px 0;
  margin: 0;
}
.dropdown-plan-list li {
  padding: 5px 10px;
  cursor: pointer;
}
.dropdown-plan-list li .plan-list {
  padding: 3px 12px;
  border-radius: 3px;
}

.custom-table table thead th .custom-popover {
  cursor: pointer;
}

.ant-popover-content .ant-popover-inner {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/views/Subscriptions/styles.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAEE;EACE,SAAA;EACA,gBAAA;EACA,UAAA;EACA,cAAA;EACA,eAAA;AAAJ;AACI;EACE,SAAA;EACA,cAAA;AACN;AACI;EACE,gBAAA;AACN;AACI;EACE,wCAAA;EACA,yBAAA;AACN;AACI;EACE,yBAAA;EACA,wCAAA;AACN;AACI;EACE,cAAA;EACA,WAAA;EACA,YAAA;AACN;;AAGA;EACE,yBAAA;EACA,oCAAA;AAAF;;AAGA;EACE,gBAAA;EACA,cAAA;EACA,SAAA;AAAF;AACE;EACE,iBAAA;EACA,eAAA;AACJ;AAAI;EACE,iBAAA;EACF,kBAAA;AAEJ;;AAGE;EACE,eAAA;AAAJ;;AAGA;EACE,UAAA;AAAF","sourcesContent":[".plan-dropdown {\n  .dropdown-menu {\n    min-width: 85px;\n  }\n  .btn {\n    border: 0;\n    background: none;\n    padding: 0;\n    color: #253944;\n    font-size: 16px;\n    &:hover {\n      border: 0;\n      color: #253944;\n    }\n    &:focus {\n      background: none;\n    }\n    &.active {\n      background-color: transparent !important;\n      color: #253944 !important;\n    }\n    &:active {\n      color: #253944 !important;\n      background-color: transparent !important;\n    }\n    svg {\n      color: #253944;\n      width: 10px;\n      height: 10px;\n    }\n  }\n}\n.plan-list.active {\n  color: #ffffff !important;\n  background-color: #4ca971 !important;\n}\n\n.dropdown-plan-list {\n  list-style: none;\n  padding: 8px 0;\n  margin: 0;\n  li {\n    padding: 5px 10px;\n    cursor: pointer;\n    .plan-list {\n      padding: 3px 12px;\n    border-radius: 3px;\n    }\n  }\n}\n.custom-table table thead th {\n  .custom-popover {\n    cursor: pointer;\n  }\n}\n.ant-popover-content .ant-popover-inner {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
