import { createSlice } from "@reduxjs/toolkit";
import { getAllUserAccounts } from "./UserApi";

const initialState = {
  isLoading: false,
  userData: [],
  isError: false,
  noUserDataFound: false,
};

const userSlice = createSlice({
  name: "userslice",
  initialState,
  reducers: {
    setFilterData: (state, action) => {
      state.userData = action.payload;
    },
    setNoUserDataFound: (state, action) => {
      state.noUserDataFound = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllUserAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllUserAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.user_browser_details.length === 0) {
        state.userData = action?.payload?.user_browser_details;
        state.noUserDataFound = true;
      }
      state.userData = action?.payload?.user_browser_details;
    });
    builder.addCase(getAllUserAccounts.rejected, (state, action) => {
      state.isLoading = false;
      state.userData = [];
      state.isError = action.error.message;
    });
  },
});

export const { setFilterData, setNoUserDataFound } = userSlice.actions;
export const getAllUserData = (state) => state.userpage.userData;
export const getNoAllUserData = (state) => state.userpage.noUserDataFound;
export default userSlice.reducer;
