import React from "react";

const Icon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="currentColor"
    viewBox="0 0 21 20"
  >
    <g fill="currentColor" clipPath="url(#clip0_706_577)">
      <path d="M1.437 13.148c-.587-.3-.994-.743-1.222-1.33a2.674 2.674 0 01-.191-.887C0 10.141-.018 9.344.036 8.56c.066-.916.58-1.58 1.401-2.054v6.643zM18.284 13.13V6.493c.173.114.33.204.467.318.58.49.928 1.114.952 1.874.024.803.042 1.618-.018 2.42-.066.88-.545 1.546-1.305 1.995-.024.018-.054.018-.096.03zM17.27 6.53c-.007-1.582-1.145-2.702-2.72-2.702h-1.108v-.27V.755c0-.443-.3-.755-.707-.76-.425-.007-.725.31-.725.766v3.061H5.043c-1.353 0-2.503 1.066-2.58 2.42-.043.712-.019 1.431-.019 2.15 0 2.186-.006 4.379 0 6.57 0 1.606 1.132 2.732 2.738 2.738h.46v.318c.007.329-.023.665.025.982.114.743 1.174 1.395 1.97.689.695-.611 1.402-1.21 2.097-1.821a.627.627 0 01.461-.174c1.432.006 2.87.006 4.307 0 .204 0 .407-.006.605-.042 1.312-.245 2.168-1.3 2.174-2.677.006-1.342 0-2.69 0-4.037-.006-1.462 0-2.935-.012-4.409zM7.157 10.158a.661.661 0 01-.677.659.66.66 0 01-.664-.665c-.006-.234 0-.461 0-.695v-.647c.006-.395.293-.683.676-.677a.66.66 0 01.665.665c.006.45.006.905 0 1.36zm4.013 3.348H8.537c-.413 0-.7-.287-.7-.683.005-.377.293-.659.694-.659h2.642c.413 0 .7.288.7.683-.006.378-.3.66-.7.66zm2.714-3.342a.665.665 0 01-.683.653.668.668 0 01-.659-.653c-.006-.234 0-.461 0-.695 0-.233-.006-.461 0-.695a.658.658 0 01.659-.647.661.661 0 01.683.653c.006.461.006.923 0 1.384z"></path>
    </g>
    <defs>
      <clipPath id="clip0_706_577">
        <path fill="currentColor" d="M0 0H20.593V20H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
