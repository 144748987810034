import React from "react";

const Icon = (props) => (
<svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="currentColor"
      viewBox="0 0 18 20"
    >
      <g fill="currentColor" clipPath="url(#clip0_393_369)">
        <path d="M12.854.453c1.319 1.533 2.595 3.024 3.871 4.515-.047.066-.113.036-.167.036H13.66c-.513 0-.799-.286-.799-.799V.662c-.006-.054-.006-.102-.006-.209z"></path>
        <path d="M16.94 6.43c-1.086.006-2.171.006-3.257.006-1.336 0-2.254-.919-2.254-2.255 0-1.318-.006-2.63.005-3.948 0-.185-.041-.227-.226-.227C8.225.012 5.243.012 2.26.012c-.132 0-.269 0-.4.024C.728.215 0 1.068 0 2.213v15.58C0 19.075.93 20 2.207 20H14.93c1.282 0 2.207-.925 2.207-2.207 0-3.722 0-7.438.006-11.16 0-.167-.036-.209-.203-.203zm-8.369 8.864H3.322a.479.479 0 01-.477-.478c0-.262.215-.477.477-.477h5.255c.263 0 .478.215.478.477 0 .263-.221.478-.484.478zm4.283-2.524H3.322a.479.479 0 01-.477-.477c0-.262.215-.477.477-.477h9.538c.263 0 .477.215.477.477 0 .263-.22.478-.483.478zm0-2.517H3.322a.479.479 0 01-.477-.477c0-.262.215-.477.477-.477h9.538c.263 0 .477.215.477.477 0 .263-.22.477-.483.477z"></path>
      </g>
      <defs>
        <clipPath id="clip0_393_369">
          <path fill="currentColor" d="M0 0H17.143V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
);

export default Icon;
