/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { AdminIcon } from "./svgicons";
import { useSelector, useDispatch } from "react-redux";
import { getUserAdmin } from "../views/Login/loginSlice";
import BrandLogoBlack from "../images/brand-logo.png";
import BurgerMenu from "../images/burger-menu.svg";
import Cookies from "js-cookie";
import {
  getDrawerValue,
  setDrawerClose,
} from "../views/Dashboard/DashboardSlice";

// Get the JWT token from cookies

function Navbar() {
  const getUsername = useSelector(getUserAdmin);
  const userDetailsString = Cookies.get("UserDetails");
  const userDetails = JSON.parse(userDetailsString);
  const dispatch = useDispatch();
  const DrawerValue = useSelector(getDrawerValue);
  const name = getUsername?.first_name
    ? getUsername?.first_name
    : userDetails.first_name;

  return (
    <nav className="navbar navbar-expand navbar-light bg-white  mb-4 static-top">
      <img
        alt="BrandLogoBlack"
        src={BrandLogoBlack}
        className="brand-logo-black"
      />
      <button
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle  ps-3 pe-0 order-1 order-lg-0"
        onClick={() => {
          dispatch(setDrawerClose(!DrawerValue));
        }}
      >
        <img alt="BurgerMenu" src={BurgerMenu} />
      </button>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown no-arrow">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="mr-3 d-none d-lg-inline text-gray-600 small">
              {name} {userDetails.last_name.charAt(0)}.
            </span>
            <AdminIcon />
          </a>
          <div
            className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            <a
              className="dropdown-item"
              href="#"
              data-toggle="modal"
              data-target="#logoutModal"
              onClick={(e) => {
                e.preventDefault();
                localStorage.clear();
                Cookies.remove("UserDetails");
                Cookies.remove("jwtToken");
                window.location.replace("/");
              }}
            >
              Logout
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
