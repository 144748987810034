import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const apiUrl = process.env.REACT_APP_API_URL;

export const logIn = createAsyncThunk(
  "admin/logIn",
  async (loginValue, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/login`, loginValue, {
        headers: {
          Authorization: "Bearer your_token",
          "Content-Type": "application/json",
        },
      });

      const token = response?.data?.access_token;
      Cookies.set("jwtToken", token);

      const userDetails = {
        first_name: response?.data?.user.first_name,
        last_name: response?.data?.user.last_name, // Fixed to use last_name
      };
      Cookies.set("UserDetails", JSON.stringify(userDetails));

      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      return rejectWithValue(errorMessage);
    }
  }
);
