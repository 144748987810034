import { combineReducers, configureStore } from "@reduxjs/toolkit";
import DashboardSlice from "./views/Dashboard/DashboardSlice";
import loginSlice from "./views/Login/loginSlice";
import userslice from "./views/Users/UserSlice";
import subcriptionSlice from "./views/Subscriptions/SubcriptionSlice";
import userdetailsSlice from "./views/User-Details/UserdetailsSlice";
import pageslice from "./views/Pages/PageSlice";
const rootReducer = combineReducers({});

const store = configureStore({
  reducer: {
    dashboard: DashboardSlice,
    loginAdmin: loginSlice,
    userpage: userslice,
    subscription: subcriptionSlice,
    user: userdetailsSlice,
    page: pageslice,
  },
});
export default store;
