import React from "react";
import Chart from "react-apexcharts";
function Userchart({ user }) {
  const interval = user.map((item) => item.Interval);
  const user_cout = user.map((item) => item.user_count);
  const isDataEmpty = interval?.length === 0 || user_cout?.length === 0;

  if (isDataEmpty) {
    return <div>No data found</div>; // Display message when no data is available
  }
  const categories = interval;

  const series = [
    {
      name: "User Overview",
      data: user_cout,
    },
  ];
  const options = {
    chart: {
      type: "area",
      height: "100%",
      width: "100%",
      toolbar: {
        show: false, // Hide the toolbar
      },
      zoom: {
        enabled: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 1,
      colors: ["rgba(54, 144, 192, 1)"],
    },
    markers: {
      size: 5,
      colors: ["rgba(54, 144, 192, 1)"],
    },

    xaxis: {
      categories: categories,
    },
    tooltip: {
      //   custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      //     const date = categories[dataPointIndex];
      //     const value = series[seriesIndex][dataPointIndex];
      //     const formattedDate = new Date(date).toLocaleDateString("en-GB");
      //     return `<div class="tooltip-custom">
      //         <div class="span-div">
      //                   <span>${formattedDate}</span>
      //                   </div>
      //                   <div class="revenue-div"><span style="color: rgba(76, 169, 113, 1)">●</span><span class="span-r"> Revenue : ${value}
      //                   </span></div>

      //                 </div>`;
      //   },
      x: {
        format: "dd/MM/yy",
      },
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: "100%",
            width: "100%",
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: "100%",
            width: "100%",
          },
        },
      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
        colorStops: [
          {
            offset: 0,
            color: "rgba(54, 144, 192, 1)",
            opacity: 0.7,
          },
          {
            offset: 100,
            color: "rgba(54, 144, 192, 0)",
            opacity: 0.9,
          },
        ],
      },
    },
  };
  return  (
    <>
      <div className="line_chart">
      <Chart options={options} series={series} type="area"  height={320} />;
      </div>
    </>
  );
  

}

export default Userchart;
