import React, { useCallback, useEffect, useRef } from "react";
import "../../css/sb-admin-2.min.css";
import BackArrow from "../../images/back-arrow.svg";
import { Download } from "../../components/svgicons";
import "./styles.scss";
import Navbar from "../../components/Navbar";
import Drawer from "../../components/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { getDrawerValue, setDrawerClose } from "../Dashboard/DashboardSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserDeatils, setuserData } from "./UserdetailsSlice";
import { getUserDetails } from "./UserDetailsApi";
import { message } from "antd";
import Loader from "../../loader/loader";

const UsersDetails = () => {
  const location = useLocation();
  const userEmail = location.state;
  const dispatch = useDispatch();
  const hasFetchedData = useRef(false);
  const DrawerValue = useSelector(getDrawerValue);
  const navigate = useNavigate();
  const userData = useSelector(getUserDeatils);
  const userDataLength = Object.keys(userData).length;

  const fetchUserDetails = useCallback(
    async (userEmail) => {
      const actionResult = await dispatch(getUserDetails(userEmail));
      if (getUserDetails.rejected.match(actionResult)) {
        console.log(" actionResult.payload ", actionResult.payload);
        message.error(
          actionResult.payload || "An error occurred while fetching user  data"
        );
      }
    },
    [dispatch]
  );
  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchUserDetails(userEmail);
      hasFetchedData.current = true;
    }
  }, [fetchUserDetails, userEmail]);
  return (
    <>
      <div id="wrapper" className={DrawerValue ? "" : "sb-sidenav-toggled"}>
        <Drawer />

        <div id="content-wrapper" className="d-flex flex-column customDashbard">
          <div
            id="content"
            onClick={() => {
              if (!DrawerValue) {
                dispatch(setDrawerClose(true));
              }
            }}
          >
            <Navbar />
            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              <div className="User-details-title">
                <a
                  onClick={() => {
                    dispatch(setuserData());
                    navigate("/users");
                  }}
                >
                  <img
                    alt="BackArrow"
                    src={BackArrow}
                    className="me-3"
                    style={{ cursor: "pointer" }}
                  />
                </a>
                User Details
              </div>
              <div className="User-details-email">
                <p>
                  Email ID : <b>{userEmail}</b>
                </p>
              </div>
              {userDataLength === 0 ? (
                <div className="User-details-loader">
                  <Loader />
                </div>
              ) : (
                <>
                  <div className="custom-table user-details-tabel mb-3">
                    <div className="table-responsive">
                      <div className="user-details-heading">
                        User account details
                      </div>

                      <table className="table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th className="text-center">Accounts</th>
                            <th className="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userData?.account_statuses.map((user, index) => (
                            <tr key={index}>
                              <td>{user?.date}</td>
                              <td className="text-center">{user.account_id}</td>
                              <td className="text-center">
                                <div className="status-border">
                                  <div
                                    className={
                                      user.status === "active"
                                        ? "status-circle active-circle"
                                        : user.status === "pending"
                                          ? "status-circle pending-circle"
                                          : "status-circle inactive-circle"
                                    }
                                  >
                                    <div className="status-dote"></div>
                                  </div>
                                  <div className="status-text">
                                    {user?.status.charAt(0).toUpperCase() +
                                      user?.status.slice(1)}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="custom-table user-details-tabel mb-3">
                    <div className="table-responsive">
                      <div className="user-details-heading">Accounts</div>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th className="text-center">Accounts</th>
                            <th className="text-center">Billing Type</th>
                            <th className="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userData?.user_account_billing.map((user) => (
                            <tr>
                              <td>{user?.date}</td>
                              <td className="text-center">
                                {user?.account_id}
                              </td>
                              <td className="text-center">
                                {user?.billing_type}
                              </td>
                              <td className="text-center">
                                <span
                                  className={
                                    user.status === "succeeded"
                                      ? "status-active"
                                      : "status-inactive"
                                  }
                                >
                                  {user.status === "succeeded"
                                    ? "Active"
                                    : "Inactive"}
                                </span>
                              </td>
                              {/* <td>
                                <span className="Deactivate">Deactivate</span>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="custom-table user-details-tabel mb-3 ">
                    <div className="table-responsive">
                      <div className="user-details-heading">
                        Payment History
                      </div>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th className="text-center">Transaction Code</th>
                            <th className="text-center">Account #</th>
                            <th className="text-center">Amount</th>
                            <th className="text-center">Method</th>
                            <th className="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userData?.user_payment_history &&
                            userData.user_payment_history.map((user, index) => (
                              <tr key={index}>
                                <td>{user?.date || "N/A"}</td>
                                <td className="text-center">
                                  {user?.transaction_code || "N/A"}
                                </td>
                                <td className="text-center">
                                  {user?.account_id || "N/A"}
                                </td>
                                <td className="text-center">
                                  {user?.amount
                                    ? `$ ${(user.amount / 100).toFixed(2)}`
                                    : "N/A"}
                                </td>
                                <td className="text-center">
                                  {user?.method?.card?.brand || '--'}
                                  {/* {user?.method?.card?.last4 || "-"} */}
                                </td>
                                <td className="text-center">
                                  <span
                                    className={
                                      user?.status === "succeeded"
                                        ? "status-active"
                                        : "status-inactive"
                                    }
                                  >
                                    {user?.status === "succeeded"
                                      ? "Paid"
                                      : "Failed"}
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </>
  );
};
export default UsersDetails;
