import { createSlice } from "@reduxjs/toolkit";
import { getUserDetails } from "./UserDetailsApi";

const initialState = {
  isLoading: false,
  userData: {},
  isError: false,
};

const userDetailsSlice = createSlice({
  name: "userdetailsslice",
  initialState,
  reducers: {
    setuserData: (state, action) => {
      state.userData = {};
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getUserDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userData = action?.payload;
      state.noSubscriptioDataFound = true;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.userData = [];
      state.isError = action.error.message;
    });
  },
});

export const { setuserData } = userDetailsSlice.actions;
export const getUserDeatils = (state) => state.user.userData;
// export const getNoUserData = (state) => state.user.noSubscriptioDataFound;
export default userDetailsSlice.reducer;
