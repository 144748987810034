import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const apiUrl = process.env.REACT_APP_API_URL;

export const getAllUserSubscriptions = createAsyncThunk(
  "admin/getAllUserSubscriptions",
  async (_, { rejectWithValue }) => {
    try {
      const token = Cookies.get("jwtToken");

      const response = await axios.get(`${apiUrl}/api/subscription_detail`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "There is some internal erorrs. ";
      return rejectWithValue(errorMessage);
    }
  }
);
