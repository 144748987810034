import React from "react";
import Chart from "react-apexcharts";
function Piechart({ piechartdata }) {
  const { active = 0, inactive = 0, pending = 0 } = piechartdata || {};

  // Check if all values are zero
  const isNoData = active === 0 && inactive === 0 && pending === 0;
  const options = {
    chart: {
      type: "pie",
      toolbar: {
        show: false, // Hide the toolbar
      },
      animations: {
        enabled: false, // Disable animations
      },
    },
    labels: [], // No labels
    legend: {
      show: false, // Hide the legend
    },
    plotOptions: {
      pie: {
        expandOnClick: false, // Disable expand on click
        donut: {
          labels: {
            show: false, // Disable labels for donut type
          },
        },
        dataLabels: {
          enabled: false, // Disable data labels
        },
        customScale: 1, // Prevent scaling
      },
    },
    labels: ["Active", "Inactive", "Pending"],
    tooltip: {
      enabled: true, // Enable tooltip
      y: {
        formatter: (value) => {
          return `${value}%`; // Show the value in the tooltip
        },
      },
      marker: {
        show: false, // Hide the marker in tooltip
      },
      style: {
        fontSize: "12px",
      },
    },
    states: {
      active: {
        filter: {
          type: "none", // Disable active state
        },
      },
      hover: {
        filter: {
          type: "none", // Disable hover state
        },
      },
    },
    colors: ["#4CA971", "#D20000", "#FAAB00"], // Colors for each segment
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
        },
      },
    ],
  };

  const series = [active, inactive, pending];

  return isNoData ? (
    <div>No data found</div>
  ) : (
    <Chart options={options} series={series} type="pie" width="320" />
  );
}

export default Piechart;
