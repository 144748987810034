import React from "react";

const Icon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 18 18"
      className="me-1"
      style={{verticalAlign:"text-bottom"}}
    >
      <path
        fill="#7E7E7E"
        d="M18 8.118V15.216c0 1.336-.817 2.412-2.061 2.711-.231.056-.473.073-.71.073-4.145.006-8.296.006-12.441 0-1.42 0-2.5-.907-2.743-2.277-.028-.152-.028-.31-.028-.462V8.32c0-.062.005-.124.011-.197C6.01 8.118 11.985 8.118 18 8.118zM14.401 2.7h.85c1.577.012 2.732 1.167 2.743 2.752v.834H.028c-.101-1.116 0-2.16.93-2.943.456-.383.974-.609 1.57-.631.35-.017.7 0 1.076 0V1.81.947C3.61.4 3.982.012 4.484 0c.523-.011.911.372.923.92.011.506 0 1.014 0 1.516v.248h7.192v-.485c0-.423-.006-.846 0-1.269.011-.546.389-.93.907-.93.512.006.89.4.89.947.01.575.005 1.15.005 1.754z"
      ></path>
    </svg>
);

export default Icon;
