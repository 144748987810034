import React from "react";

const Icon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <g fill="currentColor" clipPath="url(#clip0_393_357)">
      <path d="M12.07 0h6.72c.009.008.016.023.028.023C19.644.278 20 .755 20 1.613v8.477c0 .967-.607 1.581-1.565 1.581h-6.036c-.156 0-.313-.011-.461-.05-.681-.173-1.104-.744-1.104-1.492-.004-1.816 0-3.632 0-5.448 0-1.049-.004-2.098 0-3.143.004-.68.352-1.205.947-1.436.09-.043.192-.07.29-.102zM0 18.79V9.573c.008-.012.016-.023.02-.035.25-.845.731-1.205 1.612-1.205 1.98 0 3.957 0 5.937.003.169 0 .341.012.501.051.678.169 1.1.744 1.1 1.495.004 2.141 0 4.282 0 6.427 0 .728.004 1.46 0 2.188-.008.865-.626 1.503-1.464 1.507a1669.6 1669.6 0 01-6.25 0c-.665 0-1.217-.43-1.405-1.069A.908.908 0 000 18.79zM7.93 0c.242.117.508.204.724.36.364.274.516.677.516 1.131.004 1.23.008 2.462 0 3.691-.008.857-.626 1.48-1.483 1.483-2.063.008-4.125.008-6.188 0-.74 0-1.284-.438-1.464-1.146-.008-.032-.023-.06-.035-.09v-4.22c.023-.066.05-.129.07-.195.134-.396.384-.69.764-.87.121-.058.25-.097.375-.144h6.72zM15.413 20h-3.025c-.936 0-1.558-.615-1.558-1.55-.004-1.19-.004-2.38 0-3.573.004-.924.622-1.546 1.542-1.546 2.031-.004 4.059-.004 6.09 0 .924 0 1.534.626 1.534 1.55v3.573c0 .924-.618 1.542-1.538 1.546h-3.045z"></path>
    </g>
    <defs>
      <clipPath id="clip0_393_357">
        <path fill="currentColor" d="M0 0H20V20H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
