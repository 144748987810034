import { createSlice } from "@reduxjs/toolkit";
import { logIn } from "./loginApi";

const initialState = {
  isLoading: false,
  loginUserAdmin: {},
  isError: false,
};

const loginSlice = createSlice({
  name: "loginslice",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(logIn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.loginUserAdmin = action.payload.user;
    });
    // builder.addCase(logIn.rejected, (state, action) => {
    //   state.loginUserAdmin = {};
    // });
  },
});

export const { setdata } = loginSlice.actions;
export const getUserAdmin = (state) => state.loginAdmin.loginUserAdmin;
export default loginSlice.reducer;
