import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  tosData: "",
  privacy: "",
  isError: false,
};

const pageSlice = createSlice({
  name: "pageslice",
  initialState,
  reducers: {
    setTosData: (state, action) => {
      state.tosData = action.payload;
    },
    setPrivacyData: (state, action) => {
      state.privacy = action.payload;
    },
  },

  extraReducers: (builder) => {
    // builder.addCase(getAllUserAccounts.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(getAllUserAccounts.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   if (action?.payload?.user_browser_details.length === 0) {
    //     state.userData = action?.payload?.user_browser_details;
    //     state.noUserDataFound = true;
    //   }
    //   state.userData = action?.payload?.user_browser_details;
    // });
    // builder.addCase(getAllUserAccounts.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.userData = [];
    //   state.isError = action.error.message;
    // });
  },
});

export const { setTosData, setPrivacyData } = pageSlice.actions;
export const getTosData = (state) => state.page.tosData;
export const getPrivacyData = (state) => state.page.privacy;
export default pageSlice.reducer;
