import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const apiUrl = process.env.REACT_APP_API_URL;

export const getPieChartOfAllAccounts = createAsyncThunk(
  "admin/getPieChartOfAllAccounts",
  async (_, { rejectWithValue }) => {
    try {
      const token = Cookies.get("jwtToken");
      if (!token) {
        throw new Error("No token found"); // New error case
      }
      const response = await axios.get(`${apiUrl}/api/accounts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.log("Error occurred: ", error.message); // New log
      const errorMessage =
        error.response?.data?.message || "There are some internal errors.";
      return rejectWithValue(errorMessage);
    }
  }
);

export const getLineChartOfEarnings = createAsyncThunk(
  "admin/getLineChartOfEarnings",
  async (timePeriod, { rejectWithValue }) => {
    try {
      const token = Cookies.get("jwtToken");
      const response = await axios.get(
        `${apiUrl}/api/revenue_graph?period=${timePeriod}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "There is some internal erorrs. ";

      return rejectWithValue(errorMessage);
    }
  }
);
export const getLineChartOfUserOverview = createAsyncThunk(
  "admin/getLineChartOfUserOverview",
  async (interval, { rejectWithValue }) => {
    try {
      const token = Cookies.get("jwtToken");
      const response = await axios.get(
        `${apiUrl}/api/get_users_graph?period=${interval}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "There is some internal erorrs. ";
      return rejectWithValue(errorMessage);
    }
  }
);

export const getSalesAndSummary = createAsyncThunk(
  "admin/getSalesAndSummary",
  async (interval, { rejectWithValue }) => {
    try {
      const token = Cookies.get("jwtToken");
      const response = await axios.get(
        `${apiUrl}/api/sales_summary`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "There is some internal erorrs. ";
      return rejectWithValue(errorMessage);
    }
  }
);
