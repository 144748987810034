import React from "react";

const Icon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="currentColor"
    viewBox="0 0 16 11"
    className="ms-2"
  >
    <path
      fill="currentColor"
      d="M8 5.5L13.333 0 16 2.75 8 11 0 2.75 2.667 0 8 5.5z"
    ></path>
  </svg>
);

export default Icon;
