import { createSlice } from "@reduxjs/toolkit";
import { getAllUserSubscriptions } from "./SubscriptionApi";

const initialState = {
  isLoading: false,
  subscriptionData: [],
  isError: false,
  noSubscriptioDataFound: false,
};

const subscriptionSlice = createSlice({
  name: "subscriptionslice",
  initialState,
  reducers: {
    setNoSubscriptioDataFound: (state, action) => {
      state.noSubscriptioDataFound = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllUserSubscriptions.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllUserSubscriptions.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.subscriptions.length === 0) {
        state.subscriptionData = action?.payload?.subscriptions;
        state.noSubscriptioDataFound = true;
      }
      state.subscriptionData = action?.payload?.subscriptions;
    });
    builder.addCase(getAllUserSubscriptions.rejected, (state, action) => {
      state.isLoading = false;
      state.subscriptionData = [];
      state.isError = action.error.message;
    });
  },
});

// export const { } = subscriptionSlice.actions;
export const getAllUserSubscriptionsData = (state) =>
  state.subscription.subscriptionData;
export const getNoAllUserData = (state) =>
  state.subscription.noSubscriptioDataFound;
export default subscriptionSlice.reducer;
