import React, { useEffect, useState } from "react";
import Drawer from "../../components/Drawer";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  fetchPrivacyPolicy,
  fetchTermsOfService,
  updateTermsOfService,
  updatePrivacyPolicy,
} from "./pagesApi";
import Navbar from "../../components/Navbar";
import "../../css/sb-admin-2.min.css";
import "./styles.scss";
import Loader from "../../loader/loader";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrivacyData,
  getTosData,
  setPrivacyData,
  setTosData,
} from "./PageSlice";
import { getDrawerValue, setDrawerClose } from "../Dashboard/DashboardSlice";

const editorConfiguration = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "blockQuote",
      "insertTable",
      "|",
      "imageUpload",
      "mediaEmbed",
      "|",
      "undo",
      "redo",
    ],
  },
  language: "en",
  image: {
    toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
  },
};

const PrivacyPolicyAndTermOfServices = () => {
  const dispatch = useDispatch();
  const termOfService = useSelector(getTosData);
  const privacyPolicy = useSelector(getPrivacyData);
  const [isEditable, setIsEditable] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const DrawerValue = useSelector(getDrawerValue);
  // const [privacyPolicy, setPrivacyPolicy] = React.useState("");
  // const [termOfService, setTermOfService] = React.useState("");
  const [key, setKey] = React.useState("tos");
  const [loading, setLoading] = useState(false);
  const getPrivacyPolicy = async () => {
    try {
      const response = await fetchPrivacyPolicy();
      dispatch(setPrivacyData(response.privacy_policy));
    } catch (error) {
      console.log("get privacy policy error", error);
    }
  };

  const getTermsOfService = async () => {
    try {
      const response = await fetchTermsOfService();

      dispatch(setTosData(response.terms_of_service));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("get terms of service error", error);
    }
  };

  useEffect(() => {
    getTermsOfService();
    getPrivacyPolicy();
  }, []);

  const submit = async () => {
    if (key === "privacy") {
      try {
        setLoading(true);
        const res = await updatePrivacyPolicy({
          privacy_policy: privacyPolicy,
        });
        if (res) {
          message.success(`privacy policy  updated successfully`);
          setIsEditable(false);
          setLoading(false);
        }
      } catch (error) {
        console.log("update privacy policy error", error);
        setIsEditable(false);
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const res = await updateTermsOfService({
          terms_of_service: termOfService,
        });
        if (res) {
          message.success(`terms of conditions updated successfully`);
          setIsEditable(false);
          setLoading(false);
        }
      } catch (error) {
        console.log("update terms of conditions error", error);
        setIsEditable(false);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div id="wrapper" className={DrawerValue ? "" : "sb-sidenav-toggled"}>
        <Drawer />
        <div id="content-wrapper" className="d-flex flex-column customDashbard">
          <div
            id="content"
            onClick={() => {
              if (!DrawerValue) {
                // Only dispatch if DrawerValue is false
                dispatch(setDrawerClose(true)); // Set DrawerValue to true
              }
            }}
          >
            <Navbar />
            {/* <!-- Begin Page Content --> */}
            <div className="page-main-section">
              <div className="d-flex gap-2 align-items-center justify-content-between mb-3 ps-4 pe-4">
                <h1 className="page-title">Pages</h1>
                <div>
                  <button
                    onClick={() => {
                      setIsEditable(!isEditable);
                    }}
                    className="btn btn-sm btn-secondary me-2"
                  >
                    {isEditable ? "Cancel" : "Edit"}
                  </button>
                  <button
                    disabled={!isEditable || loading}
                    onClick={submit}
                    className="btn btn-sm btn-primary"
                  >
                    {loading ? "Editing..." : " Save"}
                  </button>
                </div>
              </div>
              <div className="policy-page-section">
                <ul className="nav nav-tabs ps-4 pe-4" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="simple-tab-0"
                      data-bs-toggle="tab"
                      href="#simple-tabpanel-0"
                      role="tab"
                      aria-controls="simple-tabpanel-0"
                      aria-selected="true"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default anchor click behavior
                        setKey("tos");
                      }}
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="simple-tab-1"
                      data-bs-toggle="tab"
                      href="#simple-tabpanel-1"
                      role="tab"
                      aria-controls="simple-tabpanel-1"
                      aria-selected="false"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default anchor click behavior
                        setKey("privacy");
                      }}
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>

                <div className="tab-content " id="tab-content">
                  <div
                    className="tab-pane active"
                    id="simple-tabpanel-0"
                    role="tabpanel"
                    aria-labelledby="simple-tab-0"
                  >
                    <div
                      className="policy-section"
                      style={{ position: "relative", height: "60vh" }}
                    >
                      {isEditable ? (
                        <CKEditor
                          editor={ClassicEditor}
                          config={editorConfiguration}
                          data={termOfService}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            dispatch(setTosData(data));
                          }}
                        />
                      ) : (
                        <>
                          {privacyPolicy === "" && termOfService === "" ? (
                            <Loader />
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: termOfService,
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className="tab-pane"
                    id="simple-tabpanel-1"
                    role="tabpanel"
                    aria-labelledby="simple-tab-1"
                  >
                    <div className="policy-section">
                      {isEditable ? (
                        <CKEditor
                          editor={ClassicEditor}
                          config={editorConfiguration}
                          data={privacyPolicy}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            dispatch(setPrivacyData(data));
                          }}
                        />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: privacyPolicy }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </>
  );
};

export default PrivacyPolicyAndTermOfServices;
