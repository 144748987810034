import React from "react";

const Icon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 35 35"
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#E6E6E6"></circle>
      <g clipPath="url(#clip0_531_50)">
        <path
          fill="#6E6E6E"
          d="M26 25.373c-.031-.156-.016-.32-.035-.477a7.838 7.838 0 00-.826-2.608 8.088 8.088 0 00-2-2.471 8.007 8.007 0 00-1.805-1.136c-.196-.09-.4-.164-.603-.25.043-.082.113-.114.168-.157a4.504 4.504 0 001.335-1.703c.34-.752.485-1.54.403-2.37a4.637 4.637 0 00-1.35-2.878A4.433 4.433 0 0019.7 10.29a4.176 4.176 0 00-1.268-.29h-.063c-.027.027-.063.012-.094.016-.031-.004-.067.011-.094-.016h-.407c-.024.024-.059.012-.086.016-.024 0-.051.004-.07-.016h-.126c-.043.059-.113.047-.168.055a4.056 4.056 0 00-.963.25c-1.703.701-2.71 1.966-2.948 3.795-.184 1.421.27 2.667 1.245 3.716.211.227.45.419.696.603-.023.043-.058.043-.086.055a8.016 8.016 0 00-3.895 3.038c-.896 1.316-1.339 2.78-1.374 4.367 0 .078 0 .121.102.121.348-.008.697-.004 1.045 0 .082 0 .106-.023.106-.106 0-.25.02-.497.055-.748.281-2.052 1.3-3.653 3.01-4.797 1.37-.916 2.897-1.256 4.534-1.041 2.047.27 3.636 1.296 4.787 2.995.729 1.073 1.077 2.276 1.104 3.568.004.102.028.129.13.125.375-.008.751-.004 1.123-.004v-.344c-.02-.02-.016-.043-.016-.067v-.055c0-.023-.003-.047.016-.066.004-.024.004-.055.004-.087zM17.99 18c-1.86-.008-3.378-1.535-3.367-3.387.012-1.856 1.527-3.364 3.375-3.36a3.386 3.386 0 013.375 3.38c-.004 1.855-1.531 3.375-3.383 3.367z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_531_50">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="translate(10 10)"
          ></path>
        </clipPath>
      </defs>
    </svg>
);

export default Icon;
