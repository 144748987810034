import React from "react";

const Icon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 256 256"
  >
    <g fill="#000" strokeMiterlimit="10" strokeWidth="1">
      <path
        d="M66.848 42.103a4.999 4.999 0 00-7.046.599L50 54.325V5a5 5 0 10-10 0v49.325l-9.802-11.623a5.001 5.001 0 00-7.645 6.447l18.624 22.085c.075.088.156.168.236.251.028.029.054.059.083.088a5.078 5.078 0 00.776.622c.23.15.471.282.722.392.05.022.101.04.151.061a5 5 0 00.813.252c.035.007.071.011.106.018.306.058.618.094.935.094.317 0 .628-.036.934-.094.035-.007.071-.01.106-.018a4.96 4.96 0 00.812-.252c.052-.021.103-.039.154-.062a5.043 5.043 0 00.856-.483c.226-.158.441-.333.64-.528.03-.029.057-.061.086-.091.079-.082.161-.161.234-.249L67.445 49.15a5 5 0 00-.597-7.047z"
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
      ></path>
      <path
        d="M79.692 90H10.307a5 5 0 01-5-5V64.648c0-2.611 1.909-4.943 4.508-5.191a5.001 5.001 0 015.492 4.976v14.528c0 .574.465 1.039 1.039 1.039h57.308c.574 0 1.039-.465 1.039-1.039V64.648c0-2.612 1.91-4.943 4.509-5.191a5.001 5.001 0 015.491 4.977V85a5.001 5.001 0 01-5.001 5z"
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
      ></path>
    </g>
  </svg>
);

export default Icon;
