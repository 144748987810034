import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const apiUrl = process.env.REACT_APP_API_URL;

export const getAllUserAccounts = createAsyncThunk(
  "admin/getAllUserAccounts",
  async (userEmail, { rejectWithValue }) => {
    try {
      const token = Cookies.get("jwtToken");
      const url = userEmail
        ? `/api/get_all_users_browser_details?email=${userEmail}`
        : `/api/get_all_users_browser_details`;
      const response = await axios.get(`${apiUrl}/${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "There is some internal erorrs. ";
      return rejectWithValue(errorMessage);
    }
  }
);
