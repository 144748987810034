import React from "react";

const Icon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="currentColor"
    viewBox="0 0 20 15"
  >
    <g clipPath="url(#clip0_393_367)">
      <path
        fill="currentColor"
        d="M5.593 6.966a9.8 9.8 0 00.186-.32C6.947 4.585 8.108 2.526 9.275.467c.263-.462.738-.61 1.13-.327.127.096.236.237.313.378 1.174 2.072 2.342 4.144 3.515 6.216.045.077.09.147.142.237.82-.397 1.635-.789 2.444-1.186.66-.321 1.32-.635 1.982-.963.32-.16.628-.186.923.045.295.231.32.546.244.892-.616 2.713-1.232 5.42-1.841 8.133-.115.507-.372.712-.898.712H2.733c-.54 0-.796-.192-.911-.725-.603-2.72-1.2-5.439-1.796-8.165-.07-.327-.007-.622.275-.84.27-.205.559-.2.86-.058 1.462.718 2.931 1.424 4.432 2.149z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_393_367">
        <path fill="currentColor" d="M0 0H20V14.605H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
