import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const apiUrl = process.env.REACT_APP_API_URL;

export const getUserDetails = createAsyncThunk(
  "admin/getUserDetails",
  async (useremail, { rejectWithValue }) => {
    try {
      const token = Cookies.get("jwtToken");

      const response = await axios.get(
        `${apiUrl}/api/get_user_account_status?email=${useremail}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "There is some internal erorrs. ";
      return rejectWithValue(errorMessage);
    }
  }
);
