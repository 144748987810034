// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dont-have-ac {
  font-size: 16px;
  font-weight: 400;
  color: #7E7E7E;
  text-decoration: none;
}
.dont-have-ac span {
  font-weight: 600;
  color: #4CA971;
}`, "",{"version":3,"sources":["webpack://./src/views/Forgot-Password/styles.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,qBAAA;AACJ;AAAI;EACI,gBAAA;EACA,cAAA;AAER","sourcesContent":[".dont-have-ac {\n    font-size: 16px;\n    font-weight: 400;\n    color: #7E7E7E;\n    text-decoration: none;\n    span {\n        font-weight: 600;\n        color: #4CA971;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
