import React from "react";

const Icon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="currentColor"
    viewBox="0 0 18 20"
  >
    <g fill="currentColor" clipPath="url(#clip0_393_363)">
      <path d="M8.904 20H2.877C1.096 20-.137 18.63 0 16.85c.137-2.604 2.055-4.795 4.657-5.343.411-.137.822-.137 1.37-.137h6.028c2.876 0 5.342 1.917 5.89 4.794.137.822.137 1.644-.274 2.466-.548.959-1.37 1.37-2.466 1.37H8.904zM13.835 4.932a4.911 4.911 0 01-4.931 4.931C6.3 9.726 4.109 7.534 4.109 4.795 4.11 2.192 6.301 0 9.041 0c2.603 0 4.794 2.192 4.794 4.932z"></path>
    </g>
    <defs>
      <clipPath id="clip0_393_363">
        <path fill="currentColor" d="M0 0H17.945V20H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
