import React, { useEffect, useState } from "react";
import LoginImg from "../../images/login-img.png";
import BrandLogo from "../../images/brand-logo.png";
import "./styles.scss";
import axios from "axios";
import { useDispatch } from "react-redux";
import { logIn } from "./loginApi";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import eyeIcon from "../../images/eye.svg";
import eyeSlash from "../../images/eye-slash.svg";

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [eyeButton, setEyeButton] = useState(false);
  useEffect(() => {
    const { email, password } = formData;
    if (email === "" || password === "") {
      setLoginButtonDisabled(true);
    }
  }, [formData.email, formData.password]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginButtonDisabled(false);
    setFormData({ ...formData, [name]: value });
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, password } = formData;
    if (!email || !password) {
      message.error(`Please enter ${!email ? "email" : "password"}`, 1.5);
      return;
    }
    try {
      const response = await dispatch(logIn(formData)).unwrap();
      if (response) {
        setLoading(false);
        navigate("/dashboard");
      }
    } catch (error) {
      message.error(error, 1.5);
      setLoading(false);
      console.error("There was an error logging in:", error);
    }
  };

  return (
    <>
      <section className="login-section">
        <div className="left-block">
          <img src={LoginImg} />
        </div>
        <div className="right-block">
          <div className="container">
            <div className="login-flex">
              <div className="logo-img">
                <img src={BrandLogo} />
              </div>
              <div className="login-heading">Login to your account</div>
              <div className="login-para">
                Welcome to Fractal Alpha, please put your login credentials
                below to start using the app.
              </div>
              <form onSubmit={onHandleSubmit}>
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  className="form-control"
                  value={formData.email}
                  onChange={handleChange}
                />
                <div className="password-feild">
                  <input
                    type={eyeButton ? "text" : "password"}
                    name="password"
                    placeholder="Password "
                    value={formData.password}
                    onChange={handleChange}
                    className="form-control"
                  />
                  <div
                    className="eye-icon"
                    onClick={() => {
                      setEyeButton(!eyeButton);
                    }}
                  >
                    {eyeButton ? (
                      <img src={eyeIcon} className="show-icon" />
                    ) : (
                      <img src={eyeSlash} className="hide-icon" />
                    )}
                  </div>
                </div>
                <button
                  disabled={loginButtonDisabled || loading}
                  type="submit"
                  className="btn custom-primary"
                >
                  {loading ? "Please wait..." : "Log in"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
