/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Drawer from "../../components/Drawer";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Pagination, message, DatePicker } from "antd";
import Navbar from "../../components/Navbar";
import "../../css/sb-admin-2.min.css";
import "./styles.scss";
import Loader from "../../loader/loader";
import { AiQueries } from "../../components/svgicons";
import { useDispatch, useSelector } from "react-redux";
import { getDrawerValue, setDrawerClose } from "../Dashboard/DashboardSlice";

const Aiqueries = () => {
  const dispatch = useDispatch();
  const DrawerValue = useSelector(getDrawerValue);
  return (
    <>
      <div id="wrapper" className={DrawerValue ? "" : "sb-sidenav-toggled"}>
        <Drawer />
        <div id="content-wrapper" className="d-flex flex-column customDashbard">
          <div
            id="content"
            onClick={() => {
              if (!DrawerValue) {
                // Only dispatch if DrawerValue is false
                dispatch(setDrawerClose(true)); // Set DrawerValue to true
              }
            }}
          >
            <Navbar />
            <div className="faq-main-section">
              <div className="d-flex gap-2 align-items-center justify-content-between mb-3 ps-4 pe-4">
                <h1 className="page-title">Chatbot FAQs</h1>
              </div>
              <div className="faq-section">
                <div className="table-responsive custom-tabel  mb-3">
                  <table className="tabel w-100">
                    <thead>
                      <tr>
                        <th>FAQs</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          1. Skill and proficiency in carrying out assignments
                          Brief explanation:|
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          2. Possesses skills and knowledge to perform the job
                          competently Brief explanation:{" "}
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          3. Skill at planning, organizing and prioritizing
                          workload Brief explanation:{" "}
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          4. Holds self accountable for assigned
                          responsibilities; sees tasks through to completion in
                          a timely manner Brief explanation:{" "}
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          5. Proficiency at improving work methods and
                          procedures as a means toward greater efficiency Brief
                          explanation:
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          6. Communicates effectively with supervisor, peers,
                          and customers Brief explanation:
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          7. Ability to work independently Brief explanation:
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          8. Ability to work cooperatively with supervision or
                          as part of a team Brief explanation:{" "}
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          9. Willingness to take on additional responsibilities
                          Brief explanation:{" "}
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          11. Adeptness at analyzing facts, problem solving,
                          decision-making, and demonstrating good judgment Brief
                          explanation:{" "}
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          12. Displays fairness towards all subordinates. Brief
                          explanation:
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          13. Ability to work independently Brief explanation:
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          14. Ability to work cooperatively with supervision or
                          as part of a team Brief explanation:{" "}
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          15. Willingness to take on additional responsibilities
                          Brief explanation:{" "}
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          16. Reliability (attendance, punctuality, meeting
                          deadlines) Brief explanation:
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          17. Reliability (attendance, punctuality, meeting
                          deadlines) Brief explanation:
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          18. Reliability (attendance, punctuality, meeting
                          deadlines) Brief explanation:
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button className="status-active">Accept</button>
                            <button className="status-inactive">Reject</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Pagination className="justify-content-end mb-3" />
              </div>
            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
      </div>
    </>
  );
};

export default Aiqueries;
