import axios from "axios";
import Cookies from "js-cookie";
const appUrl = "https://api.fractalalpha.com"
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchPrivacyPolicy = async () => {
  try {
    const response = await axios.get(`${appUrl}/get_privacy_policy`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "An error occurred";
    throw errorMessage;
  }
};

export const fetchTermsOfService = async () => {
  try {
    const response = await axios.get(`${appUrl}/get_terms_of_service`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "An error occurred";
    throw errorMessage;
  }
};

export const updatePrivacyPolicy = async (payload) => {
  try {
    const token = Cookies.get("jwtToken");
    const response = await axios.put(
      `${apiUrl}/api/update_privacy_policy`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "An error occurred";
    throw errorMessage;
  }
};

export const updateTermsOfService = async (payload) => {
  try {
    const token = Cookies.get("jwtToken");
    const response = await axios.put(
      `${apiUrl}/api/update_terms_of_service`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "An error occurred";
    throw errorMessage;
  }
};
