import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const LoginRouteProtect = ({ Component }) => {
  const navigate = useNavigate();
  const token = Cookies.get("jwtToken");
  const isAuthenticated = !!token;
  const token1 = Cookies.get("jwtToken");
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    } else if (token) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, token]);

  if (isAuthenticated) {
    window.history.forward();
  }

  return <Component />;
};

export default LoginRouteProtect;
