// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-section {
  border-top: 1px solid #D9D9D9;
}

.faq-sub-section {
  padding: 17px 24px;
}

.faq-section {
  padding: 17px 24px;
}

.custom-tabel {
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 2px 2px 10.1px rgba(0, 0, 0, 0.15);
}
.custom-tabel thead {
  background: #F5F5F5;
}
.custom-tabel tr th {
  padding: 12px 12px 12px 40px;
  font-size: 16px;
  color: #253944;
}
.custom-tabel tr th:first-child {
  min-width: 400px;
}
.custom-tabel tr th:last-child {
  text-align: center;
}
.custom-tabel tr td {
  padding: 8px 12px 8px 40px;
  border-bottom: 1px solid #D9D9D9;
  font-size: 16px;
  color: #253944;
}
.custom-tabel tr td:first-child {
  min-width: 400px;
}
.custom-tabel tr td button {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/views/Ai-Queries/styles.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;;AACA;EACI,kBAAA;AAEJ;;AAAA;EACI,kBAAA;AAGJ;;AAAA;EACI,mBAAA;EACA,mBAAA;EACA,8CAAA;AAGJ;AADI;EACI,mBAAA;AAGR;AAAQ;EACI,4BAAA;EACA,eAAA;EACA,cAAA;AAEZ;AADY;EACI,gBAAA;AAGhB;AADY;EACI,kBAAA;AAGhB;AAAQ;EACI,0BAAA;EACA,gCAAA;EACA,eAAA;EACA,cAAA;AAEZ;AADY;EACI,gBAAA;AAGhB;AADY;EACI,eAAA;AAGhB","sourcesContent":[".faq-section{\n    border-top: 1px solid #D9D9D9;\n}\n.faq-sub-section {\n    padding: 17px 24px;\n}\n.faq-section {\n    padding: 17px 24px;\n}\n\n.custom-tabel {\n    border-radius: 10px;\n    background: #FFFFFF;\n    box-shadow: 2px 2px 10.1px rgba(0, 0, 0, 0.15);\n    \n    thead {\n        background: #F5F5F5;\n    }\n    tr {\n        th {\n            padding: 12px 12px 12px 40px;\n            font-size: 16px;\n            color: #253944;\n            &:first-child {\n                min-width: 400px;\n            }\n            &:last-child  {\n                text-align: center;\n            }\n        }\n        td {\n            padding: 8px 12px 8px 40px;\n            border-bottom: 1px solid #D9D9D9;\n            font-size: 16px;\n            color: #253944;\n            &:first-child {\n                min-width: 400px;\n            }\n            button {\n                font-size: 12px;\n            }\n          \n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
