import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Navigate,
} from "react-router-dom";
import Dashboard from "../views/Dashboard/component";
import Users from "../views/Users/component";
import UsersDetails from "../views/User-Details/component";
import ForgotPassword from "../views/Forgot-Password/component";
import Login from "../views/Login/component";
import Subscriptions from "../views/Subscriptions/component";
import PrivacyPolicyAndTermOfServices from "../views/Pages/component";
import Aiqueries from "../views/Ai-Queries/component";
// import Settings from "../views/Settings/component";
import "./App.scss";
import LoginRoute from "../loginRoute";
import PrivateRoute from "../PrivateRoute";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginRoute Component={Login} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute Component={Dashboard} />}
        />
        <Route path="/users" element={<PrivateRoute Component={Users} />} />
        <Route
          path="/user-details"
          element={<PrivateRoute Component={UsersDetails} />}
        />
        <Route
          path="/subscriptions"
          element={<PrivateRoute Component={Subscriptions} />}
        />
        <Route path="/pages" element={<PrivateRoute Component={PrivacyPolicyAndTermOfServices} />} />
        <Route path="/ai-queries" element={<PrivateRoute Component={Aiqueries} />} />
        {/* <Route path="/settings" element={<PrivateRoute Component={Settings} />}  /> */}
      </Routes>
    </Router>
  );
}

export default App;
