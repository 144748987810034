/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../css/sb-admin-2.min.css";
import SearchIcon from "../../images/searchIcon.svg";
import { Pagination, message } from "antd";
import "./styles.scss";
import Drawer from "../../components/Drawer";
import Navbar from "../../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getDrawerValue, setDrawerClose } from "../Dashboard/DashboardSlice";
import { getAllUserAccounts } from "./UserApi";
import { getAllUserData } from "./UserSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader/loader";

const Users = () => {
  const tableRef = useRef(null);
  const hasFetchedData = useRef(false);
  const DrawerValue = useSelector(getDrawerValue);
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [datalaoder, setLoader] = useState(false);
  const userData = useSelector(getAllUserData);
  const navigate = useNavigate();
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setItemsPerPage(pageSize);
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [currentPage]);
  const filteredData = userEmail
    ? userData.filter((user) =>
        user.email.toLowerCase().includes(userEmail.toLowerCase())
      )
    : userData;

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const fetchAllUserAccounts = useCallback(
    async (userEmail) => {
      const actionResult = await dispatch(getAllUserAccounts(userEmail));
      if (getAllUserAccounts.rejected.match(actionResult)) {
        console.log(" actionResult.payload ", actionResult.payload);
        message.error(
          actionResult.payload ||
            "An error occurred while fetching user accounts data"
        );
      }
    },
    [dispatch]
  );

  const handleInputChange = (e) => {
    const email = e.target.value;
    setUserEmail(email);
    setLoader(true);
    setCurrentPage(1); // Reset to first page when the filter changes
  };

  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchAllUserAccounts(userEmail);
      hasFetchedData.current = true;
    }
  }, [fetchAllUserAccounts, userEmail]);

  return (
    <>
      <div id="wrapper" className={DrawerValue ? "" : "sb-sidenav-toggled"}>
        <Drawer />
        <div id="content-wrapper" className="d-flex flex-column customDashbard">
          <div
            id="content"
            onClick={() => {
              if (!DrawerValue) {
                // Only dispatch if DrawerValue is false
                dispatch(setDrawerClose(true)); // Set DrawerValue to true
              }
            }}
          >
            <Navbar />
            {/* <!-- Begin Page Content --> */}
            <div className="content-block">
              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-3">
                  <h1 className="page-title">User Account</h1>
                </div>
                <div className="dashboard-filter mb-4">
                  <form>
                    <label className="search-label">Email ID</label>
                    <div className="search-feild">
                      <input
                        type="text"
                        value={userEmail}
                        placeholder="Enter email address"
                        onChange={handleInputChange}
                      />
                      <img
                        alt="SearchIcon"
                        src={SearchIcon}
                        className="search-icon"
                      />
                    </div>
                  </form>
                </div>
                <div className="custom-table mb-3">
                  <div className="table-responsive" ref={tableRef}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S No.</th>
                          <th className="text-center">User Name</th>
                          <th className="text-center">Email</th>
                          <th className="text-center">IP Address</th>
                          <th className="text-center">Browser Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedData.length > 0 ? (
                          paginatedData.map((user, index) => (
                            <tr key={index}>
                              <td>
                                {(currentPage - 1) * itemsPerPage + index + 1}
                              </td>
                              <td className="text-center">{user.user_name}</td>
                              <td className="text-center">
                                <a
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() => {
                                    navigate("/user-details", {
                                      state: user.email,
                                    });
                                  }}
                                >
                                  {user.email}
                                </a>
                              </td>
                              <td className="text-center">{user.ip_address}</td>
                              <td className="text-center">{user.browser}</td>
                            </tr>
                          ))
                        ) : paginatedData.length === 0 && datalaoder ? (
                          <tr>
                            <td colSpan="5">
                              <div className="no-data">No data Found </div>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td colSpan="5">
                              <div
                                className="no-data"
                                style={{ position: "relative", height: "43vh" }}
                              >
                                <Loader />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {filteredData.length > 0 && (
                  <Pagination
                    current={currentPage}
                    pageSize={itemsPerPage}
                    total={filteredData.length}
                    onChange={handlePageChange}
                    showSizeChanger
                    pageSizeOptions={["10", "20", "50"]}
                    className="justify-content-end mb-3"
                  />
                )}
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </>
  );
};

export default Users;
