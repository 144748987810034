import React from 'react';
import LoginImg from "../../images/login-img.png"
import BrandLogo from "../../images/brand-logo.png"
import "./styles.scss"

const Login = () => {
    return (
<>
<section className='login-section'>
    <div className='right-block w-100'>
      <div className='container '>
      <div className='login-flex'>
            <div className='logo-img'>
                <img src={BrandLogo}/>
            </div>
            <div className='login-heading'>Forgot Password?</div>
            <div className='login-para'>Enter the email address you used when you joined and we’ll send you instructions to reset your password.</div>
            <form>
                 <input type='text' placeholder='Email Address ' className='form-control m-0'/>
                 <button type='button' className='btn custom-primary'>Submit</button>
                 <div className='text-center mt-2'>
                 <a href='#' className='dont-have-ac'>Don’t have an account? <span>Sign up</span></a>
                 </div>
            </form>
        </div>
      </div>
    </div>
</section>
</>
    );
};
export default Login;